define("ember-gridstack/components/grid-stack-item", ["exports", "ember-gridstack/templates/components/grid-stack-item"], function (_exports, _gridStackItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Common prefix shared by gridstack data attributes
  var GS_PREFIX = 'data-gs-';

  var _default = Ember.Component.extend({
    layout: _gridStackItem.default,

    /**
     * @property {Array} classNames
     */
    classNames: ['grid-stack-item'],

    /**
     * @property {Ember.Component} parentContainer - reference to the grid-stack component this component belongs to
     */
    parentContainer: Ember.computed(function () {
      return this.nearestWithProperty('gridStackContainer');
    }),

    /**
     * @method didReceiveAttrs
     * @override
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var options = Ember.get(this, 'options');

      if (options) {
        // Since attributeBindings cannot be a computed property,
        // it must be manually set when options changes
        this.set('attributeBindings', // Convert each given option into a html data attribute
        Object.keys(options).map(function (key) {
          var dataKey = GS_PREFIX + Ember.String.dasherize(key);
          return "options.".concat(key, ":").concat(dataKey);
        }));
      }
    },

    /**
     * @method didInsertElement
     * @override
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var gridStack = Ember.get(this, 'parentContainer');

      if (gridStack) {
        // Register widget with grid
        gridStack.send('addWidget', this.element);
      }
    },

    /**
     * @method willDestroyElement
     * @override
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var gridStack = this.get('parentContainer');

      if (gridStack) {
        // Make sure grid stack is updated
        gridStack.send('removeWidget', this.element);
      }
    }
  });

  _exports.default = _default;
});