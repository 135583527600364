define("@happyfoxinc/hf-nav-tabs/components/hf-nav-tab-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: '',
    currentTabValue: Ember.computed.alias('parentView.currentTabValue'),
    classNames: ['hf-tab'],
    classNameBindings: ['isActive:hf-is-active'],
    actionOnClick: '',
    isActive: Ember.computed('value', 'currentTabValue', {
      get: function get() {
        return this.get('value') === this.get('currentTabValue');
      }
    }),
    click: function click() {
      this.set('currentTabValue', this.get('value'));

      if (this.get('actionOnClick')) {
        /* eslint-disable ember/no-attrs-in-components */
        this.attrs.actionOnClick(this.get('value'));
        /* eslint-enable ember/no-attrs-in-components */
      }
    }
  });

  _exports.default = _default;
});