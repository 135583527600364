define("@happyfoxinc/hf-date-range-picker/templates/components/hf-date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vJu7v668",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,5],[\"date-range-picker_trigger\",[35,4]],null]],[12],[2,\"\\n  \"],[1,[30,[36,6],[\"calendar\"],[[\"class\"],[\"hf-u-icon-right-margin\"]]]],[2,\"\\n  \"],[10,\"span\"],[14,\"data-test-id\",\"selected-date-range\"],[12],[2,\"\\n\"],[6,[37,3],[[35,7]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[34,0]],[2,\" - \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[1,[30,[36,6],[\"disclosure-indicator\"],[[\"class\"],[\"hf-u-icon-left-margin\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"startDateFormatted\",\"endDateFormatted\",\"areStartAndEndDatesSame\",\"if\",\"triggerClass\",\"concat-strings\",\"inline-svg\",\"startDate\"]}",
    "meta": {
      "moduleName": "@happyfoxinc/hf-date-range-picker/templates/components/hf-date-range-picker.hbs"
    }
  });

  _exports.default = _default;
});