define("ember-gridstack/components/grid-stack", ["exports", "ember-gridstack/templates/components/grid-stack"], function (_exports, _gridStack) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GRID_STACK_EVENTS = ['dragstart', 'dragstop', 'resizestart', 'resizestop', 'added', 'change', 'enable', 'removed'];

  var _default = Ember.Component.extend({
    layout: _gridStack.default,

    /**
     * @property {Array} classNames
     */
    classNames: ['grid-stack'],

    /**
     * @property {Boolean} gridStackContainer - used by child components to find this component
     */
    gridStackContainer: true,

    /**
     * https://github.com/troolee/gridstack.js/tree/master/doc#api
     * @property {Object} gridStack - reference to gridstack object
     */
    gridStack: Ember.computed(function () {
      if (this.$()) {
        return this.$().data('gridstack');
      }
    }).volatile(),

    /**
     * https://github.com/troolee/gridstack.js/tree/master/doc#events
     * @property {Array} gridStackEvents - list of gridstack events
     */
    gridStackEvents: undefined,

    /**
     * @method didInsertElement
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('gridStackEvents', GRID_STACK_EVENTS);

      this._createGridStack();
    },

    /**
     * @method didUpdateAttrs
     */
    didUpdateAttrs: function didUpdateAttrs() {
      // Reset gridstack to pick up latest option changes
      this._destroyGridStack();

      this._createGridStack();
    },

    /**
     * @method willDestroyElement
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._destroyGridStack();
    },

    /**
     * @method _destroyGridstack
     * @private
     */
    _destroyGridStack: function _destroyGridStack() {
      var grid = Ember.get(this, 'gridStack');

      if (grid) {
        this.$().off('.grid-stack'); // Use `false` option to prevent removing dom elements, let Ember do that

        grid.destroy(false); // Clean up gridstack reference in JQuery node

        this.$().data('gridstack', null); // Remove 'grid-stack-instance-####' class left behind

        this.$().removeClass(function (index, css) {
          return (css.match(/grid-stack-instance-\d*/) || []).join(' ');
        });
      }
    },

    /**
     * @method _createGridStack
     * @private
     */
    _createGridStack: function _createGridStack() {
      var _this = this;

      var options = Ember.assign({}, Ember.get(this, 'options'));
      this.$().gridstack(options); // Since destroying gridstack disables it,
      // we must manually enable it

      if (!Ember.get(this, 'options.staticGrid')) {
        var grid = Ember.get(this, 'gridStack');
        var itemClass = grid.opts.itemClass;
        this.$().children(".".concat(itemClass)).each(function (i, el) {
          var $el = _this.$(el); // only enable items that are supposed to mobile


          var noMove = $el.attr('data-gs-no-move');
          var noResize = $el.attr('data-gs-no-resize');

          if (!noMove) {
            grid.movable(el, true);
          }

          if (!noResize) {
            grid.resizable(el, true);
          }
        });
      }

      Ember.get(this, 'gridStackEvents').forEach(function (eventName) {
        var action = Ember.get(_this, "attrs.on".concat(Ember.String.capitalize(eventName)));

        if (action) {
          _this.$().on("".concat(eventName, ".grid-stack"), function () {
            var _Ember$run;

            (_Ember$run = Ember.run).scheduleOnce.apply(_Ember$run, ['afterRender', this, action].concat(Array.prototype.slice.call(arguments)));
          });
        }
      });
    },
    actions: {
      /**
       * @action addWidget - add the provided widget element to the grid
       */
      addWidget: function addWidget(element) {
        var grid = Ember.get(this, 'gridStack');

        if (grid) {
          grid.makeWidget(element);
        }
      },

      /**
       * @action removeWidget - remove the provided widget element from the grid
       */
      removeWidget: function removeWidget(element) {
        var grid = Ember.get(this, 'gridStack');

        if (grid) {
          // Use `false` option to prevent removing dom element, let Ember do that
          grid.removeWidget(element, false);
        }
      }
    }
  });

  _exports.default = _default;
});