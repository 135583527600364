define("@happyfoxinc/hf-async-button/components/hf-async-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if isLoading}}
    {{inline-svg "spin-loader" class="hf-spin-loader js-test-spin-loader"}}
  {{/if}}
  {{if isActionHandlingInProgress inProgressText text}}
  
  */
  {
    "id": "Wf0Sxe/A",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"spin-loader\"],[[\"class\"],[\"hf-spin-loader js-test-spin-loader\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,2],[[35,5],[35,4],[35,3]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"inline-svg\",\"isLoading\",\"if\",\"text\",\"inProgressText\",\"isActionHandlingInProgress\"]}",
    "meta": {
      "moduleName": "@happyfoxinc/hf-async-button/components/hf-async-button.hbs"
    }
  });
  /*
    This component will handle the button loading state if promise is returned from the onClick action
    Also loading state can be enabled explicitly by setting inProgress to true
  */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    tagName: 'button',
    classNameBindings: ['isActionHandlingInProgress:hf-in-inProgress', 'isDisabled:hf-is-disabled'],
    // `isDisabled` Required when async-button used with tagName='a'
    attributeBindings: ['isDisabled:disabled'],
    inProgress: false,
    isPromisePending: false,
    isActionHandlingInProgress: Ember.computed.or('isPromisePending', 'inProgress'),
    disabled: false,
    isDisabled: Ember.computed.or('isActionHandlingInProgress', 'disabled'),
    isLoading: false,
    text: '',
    inProgressText: '',
    closePopOversOnFocus: false,
    focusIn: function focusIn() {
      if (this.get('closePopOversOnFocus')) {
        $(document).click(); // Close all open popovers
      }
    },
    click: function click() {
      var _this = this;

      if (this.onClick) {
        var actionHandler = this.onClick();

        if (actionHandler && Ember.typeOf(actionHandler.then) === 'function') {
          this.set('isPromisePending', true);
          actionHandler.finally(function () {
            if (!_this.isDestroyed) {
              _this.set('isPromisePending', false);
            }
          });
        }
      }

      return false;
    }
  }));

  _exports.default = _default;
});