define("@happyfoxinc/hf-pop-over/components/hf-pop-over", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield 
    (hash 
      trigger=(component "pop-over/trigger" 
        isOpen=isOpen 
        stopClickPropagation=stopClickPropagation 
        openOnHover=openOnHover 
        isTooltip=isTooltip 
        isNewTooltip=isNewTooltip 
        isAdjustingPopOver=isAdjustingPopOver 
        exemptedSelector=exemptedSelector 
        renderInPlace=renderInPlace
        togglePopOver=(action "togglePopOver") 
        openPopOver=(action "openPopOver") 
        closePopOver=(action "closePopOver")
      ) 
      content=(component "pop-over/content" 
        isOpen=isOpen 
        stopClickPropagation=stopClickPropagation 
        isMini=isMini 
        isTooltip=isTooltip
        isNewTooltip=isNewTooltip 
        isAdjustingPopOver=isAdjustingPopOver 
        renderInPlace=renderInPlace
        togglePopOver=(action "togglePopOver") 
        closePopOver=(action "closePopOver")
      ) 
      isOpen=isOpen 
      closePopOver=(action "closePopOver") 
      openPopOver=(action "openPopOver") 
    )
  }}
  {{#if canShowOverlay}}
    <div class="hf-confirmation-overlay hf-mod-pop-over translucent hf-js-confirmation-overlay"></div>
  {{/if}}
  */
  {
    "id": "hmNJVmnl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,11],null,[[\"trigger\",\"content\",\"isOpen\",\"closePopOver\",\"openPopOver\"],[[30,[36,8],[\"pop-over/trigger\"],[[\"isOpen\",\"stopClickPropagation\",\"openOnHover\",\"isTooltip\",\"isNewTooltip\",\"isAdjustingPopOver\",\"exemptedSelector\",\"renderInPlace\",\"togglePopOver\",\"openPopOver\",\"closePopOver\"],[[35,1],[35,7],[35,10],[35,5],[35,4],[35,3],[35,9],[35,2],[30,[36,0],[[32,0],\"togglePopOver\"],null],[30,[36,0],[[32,0],\"openPopOver\"],null],[30,[36,0],[[32,0],\"closePopOver\"],null]]]],[30,[36,8],[\"pop-over/content\"],[[\"isOpen\",\"stopClickPropagation\",\"isMini\",\"isTooltip\",\"isNewTooltip\",\"isAdjustingPopOver\",\"renderInPlace\",\"togglePopOver\",\"closePopOver\"],[[35,1],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[30,[36,0],[[32,0],\"togglePopOver\"],null],[30,[36,0],[[32,0],\"closePopOver\"],null]]]],[35,1],[30,[36,0],[[32,0],\"closePopOver\"],null],[30,[36,0],[[32,0],\"openPopOver\"],null]]]]]],[2,\"\\n\"],[6,[37,13],[[35,12]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"hf-confirmation-overlay hf-mod-pop-over translucent hf-js-confirmation-overlay\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isOpen\",\"renderInPlace\",\"isAdjustingPopOver\",\"isNewTooltip\",\"isTooltip\",\"isMini\",\"stopClickPropagation\",\"component\",\"exemptedSelector\",\"openOnHover\",\"hash\",\"canShowOverlay\",\"if\"]}",
    "meta": {
      "moduleName": "@happyfoxinc/hf-pop-over/components/hf-pop-over.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['hf-pop-over-container'],
    isMini: false,
    isOpen: false,
    openOnHover: false,
    onHoverDelay: 0,
    actionOnClose: null,
    actionOnOpen: null,
    hoverInProgress: false,
    stopClickPropagation: false,
    isAdjustingPopOver: false,
    isNewTooltip: false,
    canShowPopOverOverlay: false,
    renderInPlace: true,
    canShowOverlay: Ember.computed.and('canShowPopOverOverlay', 'isOpen'),
    isTestEnvironment: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    handleMouseEnter: function handleMouseEnter() {
      var _this = this;

      if (!this.get('isDestroyed')) {
        if (this.get('openOnHover')) {
          if (this.get('onHoverDelay')) {
            this.set('hoverInProgress', true);
            Ember.run.later(this, function () {
              if (_this.get('hoverInProgress')) {
                _this.send('openPopOver');

                _this.set('hoverInProgress', false);
              }
            }, this.isTestEnvironment ? 0 : this.get('onHoverDelay'));
          } else {
            this.send('openPopOver');
          }
        }
      }
    },
    handleMouseLeave: function handleMouseLeave(event) {
      if (this.get('exemptedSelector')) {
        if (Ember.$(event.relatedTarget).attr('data-pop-over-selector') == this.get('exemptedSelector')) {
          return false;
        }
      }

      if (!this.get('renderInPlace')) {
        if ([Ember.$(event.relatedTarget).attr('id'), Ember.$(event.relatedTarget).parent().attr('id')].includes('hf-popover-wormhole-content')) {
          return false;
        }
      }

      if (this.get('openOnHover')) {
        this.set('hoverInProgress', false);
        this.send('closePopOver');
      }
    },
    actions: {
      togglePopOver: function togglePopOver() {
        if (!this.get('isDestroyed')) {
          if (this.get('isOpen')) {
            this.send('closePopOver');
          } else {
            this.send('openPopOver');
          }
        }
      },
      openPopOver: function openPopOver() {
        this.set('isOpen', true);

        if (this.get('actionOnOpen')) {
          this.attrs.actionOnOpen();
        }
      },
      closePopOver: function closePopOver(data) {
        var _this2 = this;

        if (this.get('actionOnClose')) {
          this.attrs.actionOnClose(data);
        }

        var $popOver = this.get('isMini') ? this.$('.hf-mini-pop-over') : this.$('.hf-pop-over');
        $popOver.addClass('hf-slide-out-down');
        Ember.run.later(function () {
          if (!_this2.isDestroyed) {
            _this2.set('isOpen', false);
          }
        }, 200);
      }
    }
  }));

  _exports.default = _default;
});