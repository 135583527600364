define("@happyfoxinc/hf-nav-tabs/components/hf-nav-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash tab=(component "hf-nav-tab-item"))}}
  */
  {
    "id": "6fykj3C/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"tab\"],[[30,[36,0],[\"hf-nav-tab-item\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "@happyfoxinc/hf-nav-tabs/components/hf-nav-tabs.hbs"
    }
  });
  /*
  Reason for the empty component definition is that: 
    - The apps where this component is consumed expects it to have an backing class (ember component) so that 
      we can use attributes like class on it. Eg: {{#navTabs class="hf-updates-view"}}
    - If we dont use a backing class then this component will be treated as template-only classic component 
      which doesnt allow to customize other attributes like class.
  */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({}));

  _exports.default = _default;
});