define("@happyfoxinc/hf-date-range-picker/components/hf-date-range-picker", ["exports", "@happyfoxinc/hf-date-range-picker/components/constants", "@happyfoxinc/hf-date-range-picker/templates/components/hf-date-range-picker"], function (_exports, _constants, _hfDateRangePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _hfDateRangePicker.default,
    dateRange: '',
    startDate: null,
    endDate: null,
    maxDate: null,
    alwaysShowCalendars: true,
    direction: 'right',
    ranges: null,
    defaultDateRanges: null,
    subsetRangeIds: null,
    // Used to include only a subset of date range from the default date range list. Expected in the form of ids Eg: ['today', 'yesterday']
    supersetRanges: null,
    // Used to add ranges in addition to the default range list. Expected to be in the format of ranges/defaultDateRanges
    dateRangesList: null,
    defaultRange: null,
    format: null,
    apiDateFormat: _constants.API_DATE_FORMAT,
    defaultDateFormat: _constants.DEFAULT_DATE_FORMAT,
    classNames: 'hf-u-position-relative',
    triggerClass: 'js-date-range-picker-trigger',
    init: function init() {
      this._super.apply(this, arguments);

      this.prepareDateRangesList();
      var dateRange = this.dateRange,
          dateRangesList = this.dateRangesList;

      if (dateRange) {
        var dateRangeOption = dateRangesList.find(function (range) {
          return range.id == dateRange;
        });

        if (dateRangeOption) {
          this.setProperties({
            startDate: dateRangeOption.value[0],
            endDate: dateRangeOption.value[1]
          });
        }
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      var startDate = this.startDate,
          endDate = this.endDate,
          maxDate = this.maxDate,
          direction = this.direction,
          format = this.format,
          dateRangesList = this.dateRangesList,
          triggerClass = this.triggerClass;
      var ranges = {};
      dateRangesList.forEach(function (range) {
        ranges[range.label] = range.value;
      });
      var $picker = this.$(".".concat(triggerClass)).daterangepicker({
        // We don't want parentEl and the root element to be the same (causing random issues in different browsers), hence we are initiating the date picker in a span inside the component
        startDate: startDate,
        endDate: endDate,
        maxDate: maxDate,
        alwaysShowCalendars: true,
        parentEl: this.$(),
        autoApply: true,
        opens: direction,
        locale: {
          format: format,
          firstDay: 1 // Monday

        },
        linkedCalendars: false,
        ranges: ranges
      });
      $picker.find('.input-mini').attr('placeholder', this.get('format'));
      var picker = $picker.data('daterangepicker');
      this.set('picker', picker);
      this.$().on('apply.daterangepicker', function () {
        // run.bind(this, this.setDateRange, picker);
        _this.setDateRange(picker);
      });
      this.$().on('keydown.daterangepicker', function (event) {
        if (event.which === 13) {
          Ember.run.next(function () {
            // Date is not applied in the picker yet
            _this.setDateRange(picker);
          });
        }
      });

      if (this.get('isEmbeddedInForm')) {
        this.$(".".concat(triggerClass)).click();
      }

      Ember.run.scheduleOnce('afterRender', this, this.modifyDatePicker);
    },
    prepareDateRangesList: function prepareDateRangesList() {
      var subsetRangeIds = this.subsetRangeIds,
          supersetRanges = this.supersetRanges;
      var defaultDateRanges = this.defaultDateRanges || _constants.DEFAULT_DATE_RANGES;
      var ranges = this.ranges || defaultDateRanges;
      var dateRanges = [].concat(ranges);

      if (subsetRangeIds) {
        dateRanges = dateRanges.filter(function (range) {
          return subsetRangeIds.includes(range.id);
        });
      }

      if (Ember.isPresent(supersetRanges)) {
        dateRanges = dateRanges.concat(supersetRanges);
      }

      this.set('dateRangesList', dateRanges);
    },
    modifyDatePicker: function modifyDatePicker() {
      /**
       * By default when you hover over the dates in the date-picker, the hovered date
       * is updated in the date input fields which is confusing and hence we are overriding
       * the behavior to not to update the input fields on hover.
       */
      var calendar = this.$().find('.calendar');
      calendar.off('mouseenter.daterangepicker', 'td.available'); // Turn off the default event listener

      calendar.on('mouseenter.daterangepicker', 'td.available', $.proxy(this.hoverDate, this)); // To retain the input fields
    },
    hoverDate: function hoverDate(event) {
      var picker = this.get('picker');
      picker.hoverDate(event);
      picker.updateFormInputs();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var picker = this.get('picker');
      picker.setStartDate(this.get('startDate'));
      picker.setEndDate(this.get('endDate'));
    },
    startDateFormatted: Ember.computed('startDate', {
      get: function get() {
        var defaultDateFormat = this.defaultDateFormat;
        return this.startDate ? moment(this.startDate).format(defaultDateFormat) : '';
      }
    }),
    endDateFormatted: Ember.computed('endDate', {
      get: function get() {
        var defaultDateFormat = this.defaultDateFormat;
        return this.endDate ? moment(this.endDate).format(defaultDateFormat) : '';
      }
    }),
    areStartAndEndDatesSame: Ember.computed('startDate', 'endDate', 'apiDateFormat', {
      get: function get() {
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        var apiDateFormat = this.apiDateFormat;

        if (startDate && endDate) {
          return startDate.format(apiDateFormat) === endDate.format(apiDateFormat);
        }

        return;
      }
    }),
    setDateRange: function setDateRange(picker) {
      var _this2 = this;

      this.set('startDate', picker.startDate);
      var endDate = picker.endDate || picker.startDate; // We don't want a case in which endDate is null

      this.set('endDate', endDate);
      var chosenDateRangeOption = this.dateRangesList.find(function (range) {
        return range.label === picker.chosenLabel;
      });

      if (chosenDateRangeOption) {
        this.set('dateRange', chosenDateRangeOption.id);
      }

      var dateRange = chosenDateRangeOption ? chosenDateRangeOption.id : 'custom';
      this.set('dateRange', dateRange);
      /* eslint-disable ember/no-attrs-in-components */

      if (this.attrs.onDateRangeSet) {
        this.attrs.onDateRangeSet({
          startDate: picker.startDate,
          endDate: endDate,
          dateRange: dateRange
        });
      }
      /* eslint-enable ember/no-attrs-in-components */


      Ember.run.next(function () {
        picker.hide();

        if (_this2.get('isEmbeddedInForm')) {
          picker.show();
        }
      });
    }
  });

  _exports.default = _default;
});