define("@happyfoxinc/hf-pop-over/components/pop-over/content", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if isMiniPopOverOrTooltip}}
    {{#unless canCloseOnOutsideClick}}
      <div class="hf-mini-pop-over_close-icon hf-js-pop-over-close" data-test-id={{closeDataTestId}} {{action this.attrs.closePopOver bubbles=false}}>{{inline-svg "close-details"}}</div>
    {{/unless}}
  {{else}}
    <div class="hf-pop-over_close-icon" data-test-id={{closeDataTestId}} {{action this.attrs.togglePopOver bubbles=false}} role="button" aria-label={{closeLabelText}}>
      {{inline-svg "close-icon"}}
    </div>
  {{/if}}
  {{#if renderInPlace}}
    {{yield}}
  {{else}}
    {{#ember-wormhole to=destination}}
      <div class="hf-pop-over hf-popover-wormhole {{if isOpen "hf-slide-in-up"}} {{if isMini "hf-mini-pop-over"}}" id="hf-popover-wormhole-content">
        {{yield}}
      </div>
    {{/ember-wormhole}}
  {{/if}}
  */
  {
    "id": "t/cxeB0f",
    "block": "{\"symbols\":[\"&default\",\"@togglePopOver\",\"@closePopOver\"],\"statements\":[[6,[37,1],[[35,11]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,10],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"hf-mini-pop-over_close-icon hf-js-pop-over-close\"],[16,\"data-test-id\",[34,5]],[4,[38,7],[[32,0],[32,3]],[[\"bubbles\"],[false]]],[12],[1,[30,[36,8],[\"close-details\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"hf-pop-over_close-icon\"],[16,\"data-test-id\",[34,5]],[24,\"role\",\"button\"],[16,\"aria-label\",[34,6]],[4,[38,7],[[32,0],[32,2]],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[1,[30,[36,8],[\"close-icon\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,12]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],null,[[\"to\"],[[35,3]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"hf-pop-over hf-popover-wormhole \",[30,[36,1],[[35,2],\"hf-slide-in-up\"],null],\" \",[30,[36,1],[[35,0],\"hf-mini-pop-over\"],null]]]],[14,1,\"hf-popover-wormhole-content\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isMini\",\"if\",\"isOpen\",\"destination\",\"ember-wormhole\",\"closeDataTestId\",\"closeLabelText\",\"action\",\"inline-svg\",\"canCloseOnOutsideClick\",\"unless\",\"isMiniPopOverOrTooltip\",\"renderInPlace\"]}",
    "meta": {
      "moduleName": "@happyfoxinc/hf-pop-over/components/pop-over/content.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNameBindings: ['isOpen:hf-slide-in-up', 'isMini:hf-mini-pop-over:hf-pop-over', 'isTooltip:hf-tooltip:hf-pop-over', 'isNewTooltip:hf-new-tooltip:hf-pop-over'],
    isOpen: false,
    uniqueIdentifier: null,
    clickElement: null,
    keydownElement: null,
    attributeBindings: ['style'],
    canCloseOnOutsideClick: true,
    stopClickPropagation: false,
    reduceLeftOffsetBy: 0,
    disableAutoTopPositioning: false,
    disableAutoLeftPositioning: false,
    popOverMaxHeight: 450,
    scrollContainer: '',
    isAdjustingPopOver: false,
    isNewTooltip: true,
    position: null,
    closeDataTestId: 'pop-over-close',
    closeLabelText: 'close',
    renderInPlace: true,
    isMiniPopOverOrTooltip: Ember.computed.or('isMini', 'isTooltip', 'isNewTooltip'),
    destination: 'body',
    isTestEnvironment: false,
    didInsertElement: function didInsertElement() {
      var uniqueIdentifier = this.get('elementId');
      this.set('clickElement', "click.'".concat(uniqueIdentifier));
      this.set('keydownElement', "keydown.'".concat(uniqueIdentifier));

      if (this.get('renderInPlace')) {
        this.renderPopoverInPlace();
      } else {
        this.renderPopoverInWormhole();
      }

      Ember.$(document).on(this.get('clickElement'), Ember.run.bind(this, this.closeOnOutsideClick));
      Ember.$(document).on(this.get('keydownElement'), Ember.run.bind(this, this.closeOnEscape));
    },
    closeOnOutsideClick: function closeOnOutsideClick(event) {
      if (this && this.get('isOpen') && this.get('canCloseOnOutsideClick')) {
        var triggerElement = Ember.$(event.relatedTarget).parent();

        if (!this.get('renderInPlace') && triggerElement.attr('class')) {
          if (triggerElement.attr('class').includes('hf-popover-trigger') && triggerElement.parent().attr('id') === Ember.$("#".concat(this.get('elementId'))).parent().attr('id')) {
            return false;
          }
        }

        if (!Ember.$(event.target).closest(this.$()).length) {
          this.attrs.closePopOver();
        }
      }
    },
    closeOnEscape: function closeOnEscape(e) {
      if (e.keyCode === 27) {
        if (this && this.get('isOpen')) {
          this.attrs.closePopOver();
        }
      }
    },
    click: function click(event) {
      var target = event.target;

      if (Ember.$(target).closest(Ember.$('[data-pop-over="close"]'), this.$()).length || Ember.$(target).data('pop-over') === 'close') {
        this.attrs.closePopOver();
      }

      if (this.get('stopClickPropagation')) {
        return event.stopPropagation();
      }
    },
    renderPopoverInPlace: function renderPopoverInPlace() {
      var scrollContainer = this.get('scrollContainer');
      var $scrollContainer = Ember.$(scrollContainer);
      var $popover = this.$();
      var $container = $scrollContainer.length > 0 ? $scrollContainer : Ember.$(window);
      var containerHeight = $container.height();
      var containerWidth = $container.width();
      var topBarHeight = scrollContainer ? 0 : Ember.$('.hf-top-bar').height();
      var $trigger = this.$().siblings('.hf-popover-trigger');
      var topOffset = $trigger.length > 0 ? $trigger.offset().top + $trigger.height() / 2 : this.$().offset().top;
      var leftOffset = $popover.offset().left;
      var position = this.get('position');

      if ($scrollContainer.length > 0) {
        topOffset = topOffset - $container.offset().top;
        leftOffset = leftOffset - $container.offset().left;
      }

      var popoverHeight = $popover.height();
      var popoverWidth = $popover.width();

      if (!this.isTestEnvironment) {
        if (!this.get('disableAutoTopPositioning')) {
          if (containerHeight - topOffset < ($scrollContainer.length > 0 ? popoverHeight + 10 : popoverHeight)) {
            // 10 added as Buffer to prevent cases where popover gets hidden inside the scrollContainer
            this.setIsAdjustingPopOver(true);

            if (topOffset - topBarHeight > popoverHeight) {
              // subtracting top bar height as it's z-index is higher than the popover. When the popover tries to render on the top due to lack of window space at the bottom it sometimes goes underneath the top bar
              $popover.css('top', 'inherit');
              $popover.css('bottom', '100%');
              $popover.addClass('hf-mod-position-top');
            } else {
              var popOverMaxHeight = this.get('popOverMaxHeight'); // adjustedPopoverHeight -> popover height shouldn't be greater than popover max height

              var adjustedPopoverHeight = popoverHeight > popOverMaxHeight ? popOverMaxHeight : popoverHeight;
              var availableHeightForPopover = containerHeight - topBarHeight;
              var modifiedPopoverHeight = availableHeightForPopover <= adjustedPopoverHeight ? availableHeightForPopover - 10 : adjustedPopoverHeight;
              $popover.height(modifiedPopoverHeight);

              if (modifiedPopoverHeight !== popoverHeight) {
                $popover.css('overflow-y', 'auto');
              }
            }

            this.setIsAdjustingPopOver(false);
          }
        }

        if (!this.get('disableAutoLeftPositioning')) {
          if (containerWidth > popoverWidth && containerWidth - leftOffset < popoverWidth) {
            this.setIsAdjustingPopOver(true);
            var newLeft = containerWidth - (popoverWidth + leftOffset);
            $popover.css('left', newLeft - this.get('reduceLeftOffsetBy'));
            this.setIsAdjustingPopOver(false);
          }
        }

        if (position) {
          var _popoverHeight = $popover.outerHeight();

          var _popoverWidth = $popover.outerWidth();

          switch (position) {
            case 'top':
              $popover.css('top', -_popoverHeight);
              break;

            case 'bottom':
              $popover.css('bottom', -_popoverHeight);
              break;

            case 'left':
              $popover.css('left', -_popoverWidth);
              break;

            case 'right':
              $popover.css({
                'left': 'unset',
                'right': -_popoverWidth
              });
              break;
          }
        }
      }
    },
    renderPopoverInWormhole: function renderPopoverInWormhole() {
      var content = Ember.$('#hf-popover-wormhole-content');

      if (this.get('popOverMaxHeight') && content.outerHeight() > this.get('popOverMaxHeight')) {
        content.css({
          'height': this.get('popOverMaxHeight'),
          'max-height': this.get('popOverMaxHeight'),
          'overflow-y': 'auto'
        });
      }

      var trigger = Ember.$("#".concat(this.get('elementId'))).siblings('.hf-popover-trigger.hf-is-open');
      var stylesToBeAppliedOnContent = [['margin', '0 20px']];

      if (!this.get('disableAutoTopPositioning')) {
        if (trigger.offset().top + trigger.outerHeight() + content.outerHeight() < Ember.$(window).outerHeight()) {
          stylesToBeAppliedOnContent.push(['top', trigger.offset().top + trigger.outerHeight() - 1]);
        } else {
          var bottom = Ember.$(window).outerHeight() - trigger.offset().top;
          stylesToBeAppliedOnContent.push(['top', bottom < 0 ? '100%' : Ember.$(window).outerHeight() - content.outerHeight() - bottom]);
        }
      }

      if (!this.get('disableAutoLeftPositioning')) {
        if (trigger.offset().left + content.outerWidth() > Ember.$(window).outerWidth()) {
          stylesToBeAppliedOnContent.push(['right', 0]);
          stylesToBeAppliedOnContent.push(['min-width', '200px']);
        } else {
          if (trigger.width() < content.width()) {
            var left = trigger.offset().left - content.outerWidth() / 2;
            stylesToBeAppliedOnContent.push(['left', left < 0 ? 0 : left]);
          } else {
            stylesToBeAppliedOnContent.push(['left', trigger.offset().left + trigger.outerWidth() / 2 - content.outerWidth() / 2]);
          }
        }
      }

      content.css((0, _lodash.fromPairs)(stylesToBeAppliedOnContent));

      if (this.get('isMini')) {
        content.parent().parent().css('display', 'none');
      }

      Ember.$('#hf-popover-wormhole-content').on('mouseleave', Ember.run.bind(this, this.closeOnOutsideClick));
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(document).off(this.get('clickElement'));
      Ember.$(document).off(this.get('keydownElement'));
    },
    setIsAdjustingPopOver: function setIsAdjustingPopOver(value) {
      var _this = this;

      if (this.get('isNewTooltip')) {
        if (value) {
          this.set('isAdjustingPopOver', true);
        } else {
          // If there is adjustment to pop over, change in position is taking time
          Ember.run.later(function () {
            _this.set('isAdjustingPopOver', false);
          }, 100);
        }
      }
    }
  }));

  _exports.default = _default;
});