define("@happyfoxinc/hf-pop-over/components/pop-over/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield}}
  */
  {
    "id": "SSsr4ck/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "@happyfoxinc/hf-pop-over/components/pop-over/trigger.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember.Component.extend({
    classNames: ['hf-popover-trigger'],
    classNameBindings: ['isOpen:hf-is-open', 'isDisabled:hf-is-disabled'],
    attributeBindings: ['tabindex', 'title', 'role'],
    canCloseOtherPopOvers: true,
    stopClickPropagation: false,
    isAdjustingPopOver: false,
    isNewTooltip: false,
    tabindex: Ember.computed('canFocus', 'isDisabled', {
      get: function get() {
        return this.get('canFocus') && !this.get('isDisabled') ? '0' : null; // Returning null will not render the property from attribute bindings.
      }
    }),
    isOpen: false,
    isDisabled: false,
    canFocus: false,
    isClickInProgess: false,
    openOnHover: false,
    exemptedSelector: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    handleMouseEnter: function handleMouseEnter() {
      if (!this.get('isAdjustingPopOver') && (this.get('isTooltip') || this.get('isNewTooltip'))) {
        this.attrs.openPopOver();
      }
    },
    handleMouseLeave: function handleMouseLeave(event) {
      if (this.get('exemptedSelector')) {
        if (Ember.$(event.relatedTarget).attr('data-pop-over-selector') == this.get('exemptedSelector')) {
          return false;
        }
      }

      if (!this.get('renderInPlace')) {
        if ([Ember.$(event.relatedTarget).attr('id'), Ember.$(event.relatedTarget).parent().attr('id')].includes('hf-popover-wormhole-content')) {
          return false;
        }
      }

      if (!this.get('isAdjustingPopOver') && (this.get('isTooltip') || this.get('isNewTooltip'))) {
        this.attrs.closePopOver();
      }
    },
    click: function click() {
      var _this = this;

      if (this.get('openOnHover')) {
        return false;
      } // To prevent geeky users from opening the popover by inspecting and removing the hf-is-disabled class for the trigger


      this.set('isClickInProgress', true);

      if (!this.get('isDisabled')) {
        Ember.run.next(function () {
          _this.attrs.togglePopOver();
        });
      }

      if (this.get('stopClickPropagation')) {
        return false;
      }
    },
    focusIn: function focusIn() {
      var _this2 = this;

      if (!this.get('isOpen') && this.get('canCloseOtherPopOvers')) {
        // This check to prevent closing the popover when focus is coming from popover content (Shift + Tab)
        Ember.$(document).click(); // To close all currently open pop-overs
      }

      Ember.run.later(function () {
        // To wait for click event to fire, to check whether the focusIn was caused by a direct mouse click
        if (!_this2.isDestroyed) {
          if (!_this2.get('isClickInProgress')) {
            _this2.click();
          }

          _this2.set('isClickInProgress', false);
        }
      }, 300);
    }
  }));

  _exports.default = _default;
});