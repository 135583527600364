define("@happyfoxinc/hf-modal-dialog/components/hf-modal-dialog", ["exports", "ember-modal-dialog/components/modal-dialog"], function (_exports, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _modalDialog.default.extend({
    containerClassNames: null,
    overlayClassNames: null,
    translucentOverlay: true,
    init: function init() {
      this._super.apply(this, arguments);

      var containerClassNames = ['modal'];
      var overlayClassNames = ['modal-overlay'];

      if (this.containerClassNames) {
        containerClassNames = [].concat(_toConsumableArray(this.containerClassNames), _toConsumableArray(containerClassNames));
      }

      if (this.overlayClassNames) {
        overlayClassNames = [].concat(_toConsumableArray(this.overlayClassNames), _toConsumableArray(overlayClassNames));
      }

      this.setProperties({
        containerClassNames: containerClassNames,
        overlayClassNames: overlayClassNames
      });
    },
    didInsertElement: function didInsertElement() {
      var $modalOverlay = Ember.$('.modal-overlay');
      $modalOverlay.addClass('hf-slide-in-up');
      Ember.$('body').on('keydown.ember-modal-dialog', Ember.run.bind(this, this.handleKeyDown));
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('body').off('keydown.ember-modal-dialog');
    },
    handleKeyDown: function handleKeyDown(event) {
      if (event.keyCode === 27) {
        // Escape
        this.send('onClose');
      } else if (this.onKeyDown) {
        this.onKeyDown(event);
      }
    },
    actions: {
      onClickOverlay: function onClickOverlay() {
        if (this.clickOutsideToClose) {
          this.send('onClose');
        }
      }
    }
  });

  _exports.default = _default;
});