define("@happyfoxinc/hf-date-range-picker/components/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEFAULT_DATE_RANGES = _exports.DEFAULT_DATE_FORMAT = _exports.API_DATE_FORMAT = void 0;
  var now = new Date();
  var DEFAULT_DATE_RANGES = [{
    id: 'today',
    label: 'Today',
    value: [moment(now), moment(now)]
  }, {
    id: 'yesterday',
    label: 'Yesterday',
    value: [moment(now).subtract(1, 'days'), moment(now).subtract(1, 'days')]
  }, {
    id: 'last_seven_days',
    label: 'Last 7 days',
    value: [moment(now).subtract(6, 'days'), moment(now)]
  }, {
    id: 'last_fourteen_days',
    label: 'Last 14 days',
    value: [moment(now).subtract(13, 'days'), moment(now)]
  }, {
    id: 'last_thirty_days',
    label: 'Last 30 days',
    value: [moment(now).subtract(29, 'days'), moment(now)]
  }, {
    id: 'last_three_sixty_five_days',
    label: 'Last 365 days',
    value: [moment(now).subtract(364, 'days'), moment(now)]
  }, {
    id: 'this_week',
    label: 'This Week',
    value: [moment(now).startOf('isoweek'), moment(now)]
  }, {
    id: 'this_month',
    label: 'This Month',
    value: [moment(now).startOf('month'), moment(now)]
  }, {
    id: 'this_year',
    label: 'This Year',
    value: [moment(now).startOf('year'), moment(now)]
  }, {
    id: 'last_week',
    label: 'Last Week',
    value: [moment(now).subtract(1, 'week').startOf('isoweek'), moment(now).subtract(1, 'week').endOf('isoweek')]
  }, {
    id: 'last_month',
    label: 'Last Month',
    value: [moment(now).subtract(1, 'month').startOf('month'), moment(now).subtract(1, 'month').endOf('month')]
  }, {
    id: 'last_year',
    label: 'Last Year',
    value: [moment(now).subtract(1, 'year').startOf('year'), moment(now).subtract(1, 'year').endOf('year')]
  }];
  _exports.DEFAULT_DATE_RANGES = DEFAULT_DATE_RANGES;
  var API_DATE_FORMAT = 'YYYY-MM-DD';
  _exports.API_DATE_FORMAT = API_DATE_FORMAT;
  var DEFAULT_DATE_FORMAT = 'MMM D, YYYY';
  _exports.DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
});